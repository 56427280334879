import { Storage } from 'app/common/enums/storage';
import { Environment } from 'app/common/interfaces';

import { defaults } from './defaults.environment.sm';
import { localization } from './locale.en-us';

export const environment: Environment = {
    production: true,
    isAllowedInvalidAppSubmission: false,
    domainServer: 'api.stg.connect.schoolmint.com',
    apiUrl: 'https://api.stg.connect.schoolmint.com/',
    googleMapApiKey: 'AIzaSyBf-bpmKkLiqkKr-m5n4QLOvm9oTB1klZw',
    googleTranslateApiKey: 'AIzaSyBf-bpmKkLiqkKr-m5n4QLOvm9oTB1klZw',
    storageType: Storage.LocalStorage,
    reCaptchaSiteKey: '6LcD7XYbAAAAAO8o2F0W5_I2WLohaUVz5DkQvK_Q',
    reCaptchaV2SiteKey: '6LcE7XYbAAAAAMJV9hais4CxgSjgnZ-bdUtByxZS',
    ...defaults,
    localization,
};
